import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { InferType, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/legacy/image";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { getLeaseCalculatorPage } from "@/services/prepr/queries/getLeaseCalculatorPage";
import { parseText } from "@/utilities/htmlParser";
import { Grid } from "@/components/shared/Grid";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { Feature } from "../shared/Feature";
import { ControlledSelectList } from "../shared/Forms/ControlledSelectList";
import { ControlledFormInputFormatCurrency } from "../shared/Forms/ControlledFormInputFormatCurrency";
import Button from "../shared/Button";
import {
  getAllowedDurations,
  getMonthlyTerm,
  getObjectsAndGroups,
} from "../pages/LeaseCalculator/Queries";
import { formatPrice } from "../pages/LeaseCalculator/utils";
import LeaseCalculatorNotAvailableBlock from "./LeaseCalculatorNotAvailableBlock";

export type LeaseCalculatorCtaProps = GetDynamicContentItemProps<"LeaseCalculatorCta">;

const LeaseCalculatorCta = ({
  title,
  subtitle,
  tagline,
  tagline_mobile,
  leaseprice_label,
  button_text,
  image,
  className,
  not_available_title,
  not_available_subtitle,
  not_available_description,
}: LeaseCalculatorCtaProps) => {
  const { data: leaseCalculatorPagePreprData, error: preprError } = useQuery(
    "getLeaseCalculatorPage",
    () => getLeaseCalculatorPage(),
    {
      staleTime: 1000 * 60 * 5,
    }
  );

  const maxAmountToFinance = 15000000;
  const minAmountToFinance = 250000;

  const formSchema = object({
    objectGroupId: string(),
    objectId: string(),
    purchasePrice: number()
      .typeError("De aanschafprijs is ongeldig")
      .required("De aanschafprijs is ongeldig"),
    amountToFinance: number()
      .required()
      .min(
        minAmountToFinance,
        leaseCalculatorPagePreprData?.minimum_amount_message ??
          "De minimale aanschafprijs excl. btw is €2500. Pas de aanschafprijs aan totdat het aan het minimale bedrag voldoet om de berekening te kunnen starten."
      )
      .max(
        maxAmountToFinance,
        leaseCalculatorPagePreprData?.maximum_amount_message ??
          "Te financieren bedragen boven de € 150000 zijn voor ons maatwerk. We adviseren je om contact met ons op te nemen. Dan bespreken we jouw specifieke situatie en kunnen we je op basis daarvan een passend aanbod doen."
      ),
    monthlyTerm: number(),
  });

  const isFieldValid = async (name: string, value: Record<string, any>) => {
    try {
      await formSchema.validateAt(name, value);
      return true;
    } catch (error) {
      return false;
    }
  };

  interface FormFields extends InferType<typeof formSchema> {}

  const methods = useForm<FormFields>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      objectGroupId: "",
      objectId: "",
      purchasePrice: 0,
      monthlyTerm: 0,
    },
    mode: "onBlur",
  });

  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = methods;

  const minPurchasePrice = 250000;
  const maxPurchasePrice = 15000000;
  const objectGroupId = watch("objectGroupId");
  const objectId = watch("objectId");
  const purchasePrice = watch("purchasePrice");
  const amountToFinance = watch("amountToFinance");
  const monthlyTerm = watch("monthlyTerm");

  //TODO: use isloading state instead of retry false, aan Johan gewenst gedrag vragen
  const { data: objectsAndGroups, error: objectsAndGroupsError } = useQuery(
    "getObjectsAndGroups",
    () => getObjectsAndGroups(),
    { retry: false }
  );

  const groupOptions = objectsAndGroups
    ? objectsAndGroups.objectGroups.reduce(
        (acc, item) => ((acc[item.objectGroupId] = item.name), acc),
        {} as Record<number, string>
      )
    : {};

  const objectOptions =
    objectsAndGroups && objectGroupId
      ? objectsAndGroups.objects
          .filter((o) => o.objectGroupId == parseInt(objectGroupId))
          .reduce(
            (acc, item) => ((acc[item.objectId] = item.name), acc),
            {} as Record<number, string>
          )
      : {};

  useEffect(() => {
    if (objectId && objectOptions[parseInt(objectId)] === undefined) setValue("objectId", "");
  }, [objectGroupId, objectId, setValue]);

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  const handlePurchasePriceChange = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newTypingTimeout = setTimeout(() => {
      const purchasePrice = !isNaN(watch("purchasePrice")) ? watch("purchasePrice") : 0;
      setValue("amountToFinance", purchasePrice);
      trigger("amountToFinance");
      trigger("purchasePrice");

      setTypingTimeout(null);
    }, 1000);

    setTypingTimeout(newTypingTimeout);
  };

  const constructionYear = new Date().getFullYear();
  const constructionMonth = new Date().getMonth() + 1;

  const { data: allowedDurations } = useQuery(
    ["getAllowedDurations", { objectId, constructionYear }],
    () => {
      try {
        return getAllowedDurations(Number(objectId), Number(constructionYear));
      } catch (error) {
        throw error;
      }
    },
    { retry: false }
  );

  const duration = allowedDurations ? Math.max(...allowedDurations) : undefined;

  useQuery(
    ["getMonthlyTerm", { objectGroupId, objectId, purchasePrice, duration }],
    async () => {
      setValue("monthlyTerm", 0);
      if (!objectId || !objectGroupId) {
        return;
      }
      if (
        !(await isFieldValid("purchasePrice", { purchasePrice })) ||
        purchasePrice < minPurchasePrice ||
        purchasePrice > maxPurchasePrice
      ) {
        return;
      }
      try {
        const monthlyTerm = await getMonthlyTerm(
          Number(objectId),
          duration,
          Number(constructionYear),
          purchasePrice,
          0,
          0,
          constructionMonth
        );
        setValue("monthlyTerm", monthlyTerm);
        if (monthlyTerm) trigger("monthlyTerm");
      } catch (error) {
        throw error;
      }
    },
    { retry: false }
  );

  const router = useRouter();

  const onButtonClick = () => {
    const params = new URLSearchParams({
      objectGroupId: objectGroupId?.toString() || "",
      objectId: objectId?.toString() || "",
      purchasePrice: purchasePrice?.toString(),
    });

    const url = `/lease-calculator?${params.toString()}`;
    router.push(url);
  };

  const button_2 = useButtonTarget(leaseCalculatorPagePreprData?.button2_target?.[0]);
  const apiError = objectsAndGroupsError || preprError;

  return (
    <Feature require="enable_lease_calculator">
      <FormProvider {...methods}>
        <div
          className={clsx(
            "gradient-2 tablet:pt-20 tablet:pb-4 relative w-full py-5 text-white",
            className
          )}
        >
          <Grid>
            {apiError ? (
              <LeaseCalculatorNotAvailableBlock
                subtitle={not_available_subtitle ?? "Leaseprijs berekenen tijdelijk niet mogelijk"}
                title={not_available_title ?? ""}
                image={image}
                description={
                  not_available_description ??
                  "Het is tijdelijk niet mogelijk is om de leaseprijs direct te berekenen via onze website. We werken eraan om dit zo snel mogelijk weer beschikbaar te maken. In de tussentijd kan je via de onderstaande buttons contact met ons opnemen voor persoonlijke assistentie."
                }
                button_1_tel={leaseCalculatorPagePreprData?.button_phone_number ?? ""}
                button_2_text={leaseCalculatorPagePreprData?.button2_text ?? ""}
                button_2_target={leaseCalculatorPagePreprData?.button2_target}
              ></LeaseCalculatorNotAvailableBlock>
            ) : (
              <></>
            )}
            {!apiError && (
              <>
                <div className="tablet:col-start-1 tablet:ml-5 col-span-6 mt-3">
                  <p className="text-p text-primary-mint tablet:mb-4 tablet:text-h3-sm mb-2 font-serif tracking-wider">
                    {title}
                  </p>
                  <h2 className=" text-h2-sm tablet:text-4xl font-sans font-bold tracking-wider">
                    {subtitle}
                  </h2>
                  {tagline_mobile && (
                    <p className="tablet:hidden my-2 font-serif text-sm">{tagline_mobile}</p>
                  )}
                </div>

                <div className="tablet:col-span-4 tablet:col-start-1 tablet:ml-5 tablet:mb-10 col-span-6 mb-5 flex flex-col">
                  <div
                    data-block="LeaseCalculatorCta"
                    className={clsx(
                      className,
                      "tablet:mt-4 tablet:grid-cols-1 tablet:gap-6 relative mt-1 grid gap-4"
                    )}
                  >
                    <ControlledSelectList
                      name="objectGroupId"
                      label="Objectgroep"
                      labelClassName="text-white mb-2"
                      placeholder="maak een keuze..."
                      options={groupOptions}
                      order="ascending"
                      onChange={() => setValue("objectId", "")}
                    />
                    <ControlledSelectList
                      name="objectId"
                      label="Object"
                      labelClassName="text-white mb-2"
                      placeholder={
                        objectGroupId ? "maak een keuze..." : "kies eerst een objectgroep..."
                      }
                      options={objectOptions}
                      disabled={!objectGroupId}
                      order="ascending"
                    />
                    <ControlledFormInputFormatCurrency
                      name="purchasePrice"
                      label="Aanschafprijs excl. btw"
                      onChange={handlePurchasePriceChange}
                      disabled={false}
                    />
                  </div>
                  {amountToFinance > 0 && errors["amountToFinance"]?.message && (
                    <div className="desktop-s:p-2 mt-4 gap-2 rounded-lg font-serif">
                      {errors["amountToFinance"]?.message}
                    </div>
                  )}
                  <hr className="col-span-full my-5 bg-white" />

                  {amountToFinance <= maxAmountToFinance && (
                    // TODO: use card instead?
                    <div
                      className={
                        "text-primary-black tablet:mb-10 tablet:text-lg col-span-full mb-5 rounded-lg bg-white p-6 font-bold"
                      }
                    >
                      <div className="md:text- float-left">
                        {leaseprice_label || "Leaseprijs/maand vanaf"}
                      </div>
                      <div className="float-right mr-8 text-xl">
                        {monthlyTerm ? formatPrice(monthlyTerm) : ""}
                      </div>
                    </div>
                  )}
                  {((amountToFinance <= maxAmountToFinance &&
                    amountToFinance >= minAmountToFinance) ||
                    amountToFinance === 0) && (
                    <Button
                      className="tablet:text-lg w-max"
                      variant={"tertiary"}
                      onClick={onButtonClick}
                    >
                      {button_text || "Bereken je leaseprijs"}
                    </Button>
                  )}
                  {amountToFinance > maxAmountToFinance && (
                    <div className="tablet:col-span-6 tablet:col-start-2 tablet:flex desktop-s:col-span-4 col-span-6 mt-4 grid justify-between">
                      <Button
                        className="desktop-s:text-l col-span-6 text-base"
                        variant="tertiary"
                        type="link"
                        href={`tel:${leaseCalculatorPagePreprData?.button_phone_number}`}
                        icon={
                          <Image
                            src="/icons/contact-telephone.svg"
                            width="24"
                            height="24"
                            alt="telephone icon"
                          />
                        }
                        iconPosition="start"
                      >
                        {leaseCalculatorPagePreprData?.button_phone_number ?? ""}
                      </Button>
                      <Button
                        className="tablet:mt-0 col-span-6 mt-6"
                        variant="tertiary"
                        type={button_2.type}
                        href={button_2.href}
                        onClick={button_2.onClick}
                      >
                        {leaseCalculatorPagePreprData?.button2_text ?? ""}
                      </Button>
                    </div>
                  )}
                </div>
                <div className="tablet:col-span-4 tablet:col-start-7 tablet:mt-20 tablet:block tablet:text-4xl hidden font-sans font-bold text-white">
                  {tagline ? parseText(tagline) : ""}
                </div>
                <div className="tablet:block relative left-10 top-[716px] hidden h-[700px] w-[508px] -translate-x-1/2 -translate-y-full">
                  <div className="relative size-full ">
                    {image?.[0]?.default && (
                      <Image
                        src={image?.[0]?.default}
                        alt={image?.[0]?.description || ""}
                        layout="fill"
                        objectFit="cover"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </Grid>
        </div>
      </FormProvider>
    </Feature>
  );
};

export default LeaseCalculatorCta;
